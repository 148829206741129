.interacao {
	padding: 15px;
	background: #FAFAFA;
	border: 1px solid #EEE;
	margin-bottom: 7px;
}

.interacao h3 {
	margin: 0;
}

.interacao p {
	margin: 0;
}

.interacao .info {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.interacao .info p {
	font-size: 12px;
	line-height: 14px;
	text-align: right;
}
.interacao .info p i {
	display: block;
	font-size: 11px;
}