@import '~antd/dist/antd.min.css';
@import "~@ant-design/flowchart/dist/index.css";

:root {
	--cor-1: #090;
}

button:hover {
	cursor: pointer;
}

* {
	box-sizing: border-box;
	outline: none;
}

/*
.ant-modal-content {
	background: var(--cor-1);
}
*/


body {
	background: #EEE;
}


h1 {
	font-size: 22px;
}



form .acoes {
	text-align: right;	
}
form .acoes button {
	margin-left: 7px;
}




.table-field-edit {
	display: flex;
	align-items: center;
}
.table-field-edit button {
	margin: 0;
	margin-left: 7px;
	background: none;
	border: none;
	padding: 0;
	height: 18px;
}
.table-field-edit button svg {
	font-size: 16px;
	padding: 0;
	margin: 0;	
	color: #777;
}


.lista-switch .item {
	height: 45px;
	padding: 0 10px;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	justify-content: space-between;	
	margin-bottom: 25px;
}
.lista-switch .item p {
	margin: 0;
	font-size: 16px;
}



.container-full-flex {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}



.vazio {
	color: #AAA;
	font-size: 12px;
}

.box-vazio {
	background: #F5F5F5;
	display: flex;
	height: 100px;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-style: italic;
	color: #999;
	border: 1px dashed #CCC;
}