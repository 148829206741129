.acesso-negado {
	display: flex;
	width: 100%;
	max-width: 600px;
	background: #FFF;
	padding: 25px;
	border-radius: 10px;
	box-shadow: 0 0 5px #555;
	margin: 50px auto 0;
}

.acesso-negado .info {
	margin-left: 20px;
}