.auth-login {	
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}


.auth-login .logo img {
	max-width: 350px;
}


.auth-login .box-login {
	display: flex;
	flex-direction: column;	
	width: 100%;
	max-width: 450px;
	background: #FFF;
	box-shadow: 0 0 3px #bbb;
	padding: 15px;
	margin: 35px auto;
}

.auth-login .box-login h1 {
	margin-bottom: 25px;
	display: flex;
	align-items: center;
}
.auth-login .box-login h1 svg {
	margin-right: 7px;
}


.auth-login .voltar a {
	font-size: 12px;
	color: #777;
	display: flex;
	align-items: center;
}
.auth-login .voltar a svg {
	margin-right: 5px;
}