.follow-destaque {
	background: #fffcc7;
}
.follow-novo {
	background: #c9e9ff;
}

.table-follows .ant-table-tbody > tr:hover > td {
	background: unset;
  }





.legenda-table .follow-destaque,
.ant-table-tbody .follow-destaque {
	background: #fffcc7;	
}

.legenda-table .follow-novo,
.ant-table-tbody .follow-novo {
	background: #c9e9ff;	
}
