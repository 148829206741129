.area-inicial {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	align-items: center;
	justify-content: center;
	padding-top: 50px;
}

.area-inicial .logo {
	margin-bottom: 40px;
}
.area-inicial .logo img {
	max-width: 350px;	
}


.area-inicial .areas {
	margin-bottom: 40px;
}

.area-inicial .areas .item {
	background: #FFF;
	padding: 25px;
	box-shadow: 0 0 5px #999;
	border-radius: 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.area-inicial .areas .item:hover {
	cursor: pointer;
	opacity: 0.7;
}
.area-inicial .areas .item p {
	margin: 15px 0 0;
	display: flex;
	align-items: center;
}
.area-inicial .areas .item p svg {
	margin-left: 5px;
}


.area-inicial .link-admin {
	display: flex;
	align-items: center;
	justify-content: center;	
}
.area-inicial .link-admin a {
	display: flex;
	align-items: center;	
	color: #777;
	font-size: 12px;
}
.area-inicial .link-admin a svg {
	margin-right: 5px;
	color: #777;
}