.admin {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
}

header {
	background: #222;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px;	
}
header .logo {
	color: #888;	
}
header .logo span {
	color: #0b82d3;
}
header nav {
	display: flex;
}
header nav a {
	color: #999;
	padding: 0 20px;
	text-transform: uppercase;
	font-size: 12px;
	display: flex;
	align-items: center;
}
header nav a svg {
	margin-right: 5px;
	font-size: 14px;
}
header nav a:hover {
	color: #FF0;
}


main {
	display: flex;
	flex: 1;
}


main nav.open {
	width: 200px;	
	height: calc(100vh - 50px - 30px);
	background: #333;
	transition: width 300ms ease-out;
	overflow-y: auto;
}
main nav.close {
	width: 50px;	
	background: #333;
	transition: width 300ms ease-out;
	transition-delay: 300ms;
	overflow-y: auto;
	overflow-x: hidden;
}



main nav .logo {
	height: 50px;
	display: flex;	
	align-items: center;
	justify-content: center;
	color: #777;
	margin: 15px auto;	
}
main nav .logo img {
	max-width: 130px;
}

main nav a {
	color: #fff;
	display: flex;
	background: #444;
	height: 40px;
	align-items: center;		
	margin-bottom: 1px;
}
main nav.open a {
	padding: 0 15px;
	justify-content: flex-start;	
}
main nav.close a {
	padding: 0 5px;
	justify-content: center;	
}
main nav.open a span {
	width: 100%;
	transition: opacity 300ms ease-out, width 0ms ease-out;
	transition-delay: 300ms;
	opacity: 1;
}
main nav.close a span {
	width: 0;
	opacity: 0;			
}


main nav a:hover {
	color: #FF0;
	background: #393939;
}


main nav a.bt-recolhe {
	background: none;
	color: #0b82d3;
	font-size: 12px;
}
main nav a.bt-recolhe svg {
	font-size: 12px;
}

main nav a svg {	
	font-size: 18px;
}
main nav.open a svg {
	margin-right: 7px;
}
main nav.close a svg {
	margin-right: 0;
}

main nav h2 {
	color: #0b82d3;
	margin: 10px auto 0;
	font-weight: normal;
	font-size: 18px;	
	align-items: center;
	height: 45px;	
	padding: 0 15px;
}
main nav.open h2 {
	display: flex;
}
main nav.close h2 {
	display: none;
}


main section {	
	overflow-y: auto;	
	height: calc(100vh - 50px - 30px);	
	padding: 15px 15px 150px !important;
	flex: 1;	
}
main section nav {
	background: none;
}

.ant-modal-body .submenu,
main section .submenu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}
.ant-modal-body .submenu nav,
main section .submenu nav {
	display: flex;	
}

.ant-modal-body .submenu nav button,
main section .submenu nav button {
	margin-right: 7px;
}




footer {
	background: #222;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 11px;
}



main section nav {
	background: none;
}



main section h1 {		
	font-size: 22px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #333;
	padding: 0 15px;
	font-weight: 400;
}
main section h1 svg {
	margin-right: 7px;
}


.ant-modal-body h1,
.ant-modal-body h2, 
main section h2 {		
	font-size: 20px;
	display: flex;
	align-items: center;			
	border-bottom: 1px solid #333;
	margin-bottom: 15px;
	font-weight: 700;
}
.ant-modal-body h1 svg,
.ant-modal-body h2 svg,
main section h2 svg {
	margin-right: 8px;
}

.ant-modal-body .acoes {
	margin-top: 25px;
	text-align: right;
}






.widget {
	box-shadow: 0 0 3px #999;
	padding: 15px;
	background: #fff;
}

.widget h2 {
	background: #555;
	color: #FFF;
	font-weight: 300;
	padding: 0 15px;
	font-size: 18px;
	display: flex;
	height: 35px;
	align-items: center;
	border: none;	
}
.widget h2 svg {
	margin-right: 7px;
	font-size: 20px;
}



.infoItem {
	margin-bottom: 15px;
}
.infoItem p {
	margin: 0;
}




.box {
	background: #fff;
	padding: 15px;
	box-shadow: 0 0 3px #999;		
	margin-bottom: 25px;
}
.box h2 {
	background: #555;
	color: #FFF;
	font-weight: 300;
	padding: 0 15px;
	font-size: 18px;
	display: flex;
	height: 35px;
	align-items: center;
	border: none;
}



.legenda-table {
	display: flex;
	margin-top: 15px;
}

.legenda-table .item {
	display: flex;
	align-items: center;
	margin-right: 35px;
}

.legenda-table .item .legenda {
	width: 30px;
	height: 30px;
	margin-right: 5px;
	box-shadow: 0 0 3px #BBB;
}
.legenda-table .item p {
	margin: 0;
}


.legenda-table .operacao-inserir,
.ant-table-tbody .operacao-inserir, 
.ant-table-tbody .operacao-inserir .ant-table-cell  {
	background: #cef6cf;		
}



.legenda-table .operacao-alterar,
.ant-table-tbody .operacao-alterar, 
.ant-table-tbody .operacao-alterar .ant-table-cell {
	background: #fffcc7;	
}

.legenda-table .operacao-info,
.ant-table-tbody .operacao-info,  
.ant-table-tbody .operacao-info .ant-table-cell  {
	background: #c9e9ff;	
}

.legenda-table .operacao-default,
.ant-table-tbody .operacao-default,  
.ant-table-tbody .operacao-default .ant-table-cell  {
	background: #ffffff;	
}

.legenda-table .operacao-excluir,
.ant-table-tbody .operacao-excluir, 
.ant-table-tbody .operacao-excluir .ant-table-cell {
	background: #f6cece;	
}


