/* ********** BUTTON *********** */
.ant-btn {
	display: inline-flex;
	align-items: center;
}
.ant-btn span {
	margin: 0;	
}
.ant-btn svg {
	margin-right: 7px;	
	zoom: 1.2;
}
.ant-btn-sm svg {
	margin-right: 8px;	
	zoom: 1;
}
.ant-btn-lg svg {
	margin-right: 6px;	
	zoom: 1.35;
}
.ant-btn-loading svg {
	margin-right: 0;		
}




.ant-btn-primary:hover {
	opacity: 0.7;
}




/* ********** BUTTON DEFAULT ********** */
.ant-btn.button-default {
	border-color: #cbcbcb;
	color: #666666;
}
.ant-btn-primary.button-default {
	background: #f1f1f1;	
	color: #666666;
	text-shadow: none;
	box-shadow: none;	
}

/* ********** BUTTON SUCCESS ********** */
.ant-btn.button-success {
	border-color: #8cb753;
	color: #8cb753;
}
.ant-btn-primary.button-success {
	background: #8cb753;	
	color: #FFF;
}

/* ********** BUTTON WARNING ********** */
.ant-btn.button-warning {
	border-color: #ffa046;
	color: #ffa046;
}
.ant-btn-primary.button-warning {
	background: #ffa046;	
	color: #FFF;
}



.ant-btn-primary.button-icon {
	padding: 0 6px !important;
}



.no-action .ant-table-tbody td:last-child {
	text-align: left;
}
.ant-table-tbody td:last-child {
	text-align: right;
}
.ant-table-tbody td:last-child button {
	margin-left: 5px;
	font-size: 11px;
	height: 20px;
}
.ant-table-tbody td:last-child button svg {
	margin-right: 5px;
}

.ant-table-tbody td:last-child button.button-icon svg {
	margin-right: 0;
}

.ant-table-tbody .excluido {
	background: #fb9494;
	opacity: 0.4;
}













.ant-upload-drag-container > div {
	display: flex;
	align-items: center;
	justify-content: center;
}
.ant-upload-drag-container > div h2 {
	border: none;
	margin: 0;
	margin-left: 10px;
}




.ant-form hr {
	border: none;
	border-bottom: 1px solid #aaa;
	margin-bottom: 15px;
}

.ant-form .ant-form-item-label label {
	font-weight: 700;
}

.ant-form .ant-form-item {
	margin-bottom: 16px;
}



.row-dragging {
	background: #fafafa;
	border: 1px solid #ccc;
  }
  
  .row-dragging td {
	padding: 16px;
  }
  
  .row-dragging .drag-visible {
	visibility: visible;
  }



  tr.drop-over-downward td {
	border-bottom: 2px dashed #1890ff;
  }

  tr.drop-over-downward:hover {
	cursor: move;
  }
  
  tr.drop-over-upward td {
	border-top: 2px dashed #1890ff;
  }